<template>
<columns>
    <column>
        <columns>
            <column>
                <h4 class="title is-4">Email Branding</h4>
                <p>
                    This image will be used in all email notifications sent from your account.
                </p>
                <p class="has-text-grey">
                    <span class="has-text-weight-bold">Note:</span> All uploaded images will be resized to 450px wide by 200px high.
                </p>
                <div class="mt-4">
                    <file-input :error="$root.errors.file" @select="setEmailLogo">
                        Logo
                    </file-input>
                </div>
                <action-button :working="uploading_email_logo" @click="uploadEmailLogo" class="mt-3 is-rounded">
                    Upload
                </action-button>
            </column>
            <column class="is-4">
                <img v-if="account.email_logo" :src="account.email_logo" alt="">
            </column>
        </columns>
    </column>
    <column>
        <columns>
            <column>
                <h4 class="title is-4">Report Branding</h4>
                <p>
                    This image will be used on all PDF reports generated on your account.
                </p>
                <p class="has-text-grey">
                    <span class="has-text-weight-bold">Note:</span> All uploaded images will be resized to 400px wide by 250px high.
                </p>
                <div class="mt-4">
                    <file-input :error="$root.errors.file" @select="setReportLogo">
                        Logo
                    </file-input>
                </div>
                <action-button :working="uploading_report_logo" @click="uploadReportLogo" class="mt-3 is-rounded">
                    Upload
                </action-button>
            </column>
            <column class="is-4">
                <img v-if="account.report_logo" :src="account.report_logo" alt="">
            </column>
        </columns>
    </column>
</columns>
</template>
<script>
import { auth as backend } from '@/api'
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        uploading_email_logo: false,
        email_logo: '',
        uploading_report_logo: false,
        report_logo: '',
    }),

    methods: {
        setEmailLogo(file) {
            this.email_logo = file
        },
        setReportLogo(file) {
            this.report_logo = file
        },
        uploadEmailLogo() {
            this.uploading_email_logo = true
            backend.uploadEmailLogo({
                file: this.email_logo
            }, ({data}) => {
                this.uploading_email_logo = false
                this.$store.commit('auth/updateEmailLogo', data)
            }, () => {
                this.uploading_email_logo = false
            })
        },
        uploadReportLogo() {
            this.uploading_report_logo = true
            backend.uploadReportLogo({
                file: this.report_logo
            }, ({data}) => {
                this.$store.commit('auth/updateReportLogo', data)
                this.uploading_report_logo = false
            }, () => {
                this.uploading_report_logo = false
            })
        }
    },

    computed: {
        ...mapGetters('auth', [
            'account'
        ])
    }
}
</script>